import { useEffect } from 'react';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/es-mx';
import 'dayjs/locale/es';
import 'dayjs/locale/pt-br';
import localizedFormat from 'dayjs/plugin/localizedFormat';

const getLocale = (locale: string) => {
  switch (locale) {
    case 'en-US':
      return 'en';
    case 'en-CA':
      return 'en-ca';
    case 'fr-CA':
      return 'fr-ca';
    case 'es-MX':
      return 'es-mx';
    case 'es-419':
      return 'es';
    case 'pt-BR':
      return 'pt-br';
    default:
      return 'en';
  }
};

const useDayjsLocale = () => {
  const { locale } = useRouter();

  useEffect(() => {
    if (!locale) return;
    dayjs.locale(getLocale(locale));
    dayjs.extend(localizedFormat);
  }, [locale]);
};

export default useDayjsLocale;
