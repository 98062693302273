import { useEffect } from 'react';
import Link from 'next/link';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import {
  PRICE_SPIDER_ACCOUNT,
  PRICE_SPIDER_CONFIG_LOCAL,
  PRICE_SPIDER_CONFIG_ONLINE,
  PRICE_SPIDER_SCRIPT_URL,
} from 'helpers/constants/priceSpider';
import styles from './PriceSpider.module.scss';
import { appLocales } from '../../../helpers/constants/appLocales';

export const PriceSpiderHead = () => {
  return (
    <>
      <meta name="ps-account" content={PRICE_SPIDER_ACCOUNT} />
      <meta name="ps-country" content="US" />
      <meta name="ps-language" content="en" />
      <script src={PRICE_SPIDER_SCRIPT_URL} async id="ps-script" />
    </>
  );
};

interface PriceSpiderSnippetProps {
  sku: string;
  locale: string;
}

export const PriceSpiderSnippet = ({ sku, locale }: PriceSpiderSnippetProps) => {
  useEffect(() => {
    sku && window.PriceSpider?.rebind?.();
  }, [sku]);

  switch (locale) {
    case appLocales.ES_MX:
      return (
        <Container data-testid="where-to-buy" className={styles['where-to-buy']}>
          <h2 className={styles.mx_title}>Dónde Comprar</h2>
          <p className={styles.mt_2} data-testid="wtb-mx-content">
            Al adquirir productos Nikon con cualquier distribuidor autorizado usted obtendrá beneficios como:
          </p>
          <ul className={styles.mx_ul}>
            <li>Garantía con Nikon México, S.A. de C.V. ( consultar detalles en la póliza del producto )</li>{' '}
            <li>Derecho a recibir servicio postventa dentro y fuera de la garantía.</li>{' '}
          </ul>
          <p className={styles.mb_3}>
            Los productos adquiridos fuera de la red de distribuidores autorizados de Nikon México, S.A. de C.V. NO
            califican para estos beneficios.
          </p>
          <Container>
            <Row className="justify-content-center padding-wide">
              <Col className="d-flex align-items-center justify-content-center text-center">
                <Link href="https://www.amazon.com.mx/">
                  <a target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                    <img
                      src={'/images/wtb-mx/wtb_amazon_mx.jpg'}
                      alt={'Amazon Mexico'}
                      width="180px"
                      height="117px"
                      style={{ objectFit: 'contain' }}
                    />
                  </a>
                </Link>
              </Col>
              <Col className="d-flex align-items-center justify-content-center text-center">
                <Link href="http://www.liverpool.com.mx/tienda?s=nikon">
                  <a target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                    <img
                      src={'/images/wtb-mx/wtb_liverpool.jpg'}
                      alt={'Liverpool'}
                      width="180px"
                      height="75px"
                      style={{ objectFit: 'contain' }}
                    />
                  </a>
                </Link>
              </Col>
              <Col className="d-flex align-items-center justify-content-center text-center">
                <Link href="http://www.sanborns.com.mx/Paginas/Familia.aspx?idFamily=100303">
                  <a target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                    <img
                      src={'/images/wtb-mx/wtb_sanborns.jpg'}
                      alt={'Sanborns'}
                      width="180px"
                      height="95px"
                      style={{ objectFit: 'contain' }}
                    />
                  </a>
                </Link>
              </Col>
            </Row>
            <Row className="justify-content-center padding-wide">
              <Col className="d-flex align-items-center justify-content-center text-center">
                <Link href="http://www.fotomecanica.mx/marcas/nikon/fotografia/camaras-dslr.html">
                  <a target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                    <img
                      src={'/images/wtb-mx/wtb_Fotomecanica_Bolanos.jpg'}
                      alt={'Fotomecanica Bolanos'}
                      width="180px"
                      height="129px"
                      style={{ objectFit: 'contain' }}
                    />
                  </a>
                </Link>
              </Col>
              <Col className="d-flex align-items-center justify-content-center text-center">
                <Link href="https://www.laboratoriosjulio.mx/collections/nikon">
                  <a target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                    <img
                      src={'/images/wtb-mx/wtb_Laboratorios_Julio.jpg'}
                      alt={'Laboratorios Julio'}
                      width="180px"
                      height="129px"
                      style={{ objectFit: 'contain' }}
                    />
                  </a>
                </Link>
              </Col>
              <Col className="d-flex align-items-center justify-content-center text-center">
                <Link href="http://www.vyorsa.com.mx/catalogsearch/result/?q=NIKON">
                  <a target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                    <img
                      src={'/images/wtb-mx/wtb_vyorsa.jpg'}
                      alt={'Vyorsa'}
                      width="180px"
                      height="75px"
                      style={{ objectFit: 'contain' }}
                    />
                  </a>
                </Link>
              </Col>
            </Row>
          </Container>
        </Container>
      );
    // es_419 is for LATAM
    case appLocales.ES_LATAM:
      return (
        <Container data-testid="where-to-buy" className={styles['where-to-buy']}>
          <h2 className={styles.mx_title}>Dónde Comprar</h2>
          <p className={styles.mt_2} data-testid="wtb-latam-content">
            En la actualidad no tenemos disponible la herramienta de Dónde Comprar. Por favor contacte a un distribuidor
            en su país para sus preguntas sobre el producto que busca.
          </p>
          <p>
            Encuentre un distribuidor de productos{' '}
            <a
              href="https://www.nikonamericalatina.com/content/nikonamericas"
              target="_blank"
              rel="noopener noreferrer"
            >
              Nikon de América.
            </a>
          </p>
        </Container>
      );
    default:
      return (
        <Container data-testid="where-to-buy" className={styles['where-to-buy']}>
          <h2 className={styles.title}>Where To Buy</h2>
          <Tabs id="wtb-tabs" className={styles.tabs} defaultActiveKey="local">
            <Tab eventKey="local" title="Local">
              <div
                data-testid="spider-local"
                className="ps-widget"
                ps-config={PRICE_SPIDER_CONFIG_LOCAL}
                ps-sku={sku}
              ></div>
            </Tab>
            <Tab eventKey="online" title="Online">
              <div
                data-testid="spider-online"
                className="ps-widget"
                ps-config={PRICE_SPIDER_CONFIG_ONLINE}
                ps-sku={sku}
              ></div>
            </Tab>
          </Tabs>
        </Container>
      );
  }
};
