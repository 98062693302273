import dynamic from 'next/dynamic';
const OrderStatusTastic = dynamic(() => import('./account/order-status'));
const ShippingLabelTastic = dynamic(() => import('./account/shipping-label'));
const AnnouncementBarTastic = dynamic(() => import('./bar/announcment'));
const CategorySliderTastic = dynamic(() => import('./category-slider'));
const HeroTastic = dynamic(() => import('./content/hero'));
const Tile = dynamic(() => import('./content/tile'));
const TilesGroupTastic = dynamic(() => import('./content/tiles-group'));
const ContentSliderTastic = dynamic(() => import('./content-slider'));
const ContentstackCategoryLandingTastic = dynamic(() => import('./contentstack/contentstack-category-landing'));
const ContentstackContentTastic = dynamic(() => import('./contentstack/contentstack-content'));
const ContentstackContentListTastic = dynamic(() => import('./contentstack/contentstack-content-list'));
const ContentstackLNEArticlePageTastic = dynamic(() => import('./contentstack/contentstack-lne-article-page'));
const ContentstackLNECategoryPageTastic = dynamic(() => import('./contentstack/contentstack-lne-category-page'));
const ContentstackLNECollectionPageTastic = dynamic(() => import('./contentstack/contentstack-lne-collection-page'));
const ContentstackLNEGlossaryTastic = dynamic(() => import('./contentstack/contentstack-lne-glossary'));
const ContentstackLNEHomepageTastic = dynamic(() => import('./contentstack/contentstack-lne-homepage'));
const ContentstackLNESearchResultsTastic = dynamic(() => import('./contentstack/contentstack-lne-search-results'));
const ContentstackNikonAmbassadorsTastic = dynamic(() => import('./contentstack/contentstack-nikon-ambassadors'));
const ContentstackNikonMentorsTastic = dynamic(() => import('./contentstack/contentstack-nikon-mentors'));
const ContentstackPageSingleTastic = dynamic(() => import('./contentstack/contentstack-page-single'));
const ContentstackPressRoomArticleTastic = dynamic(() => import('./contentstack/contentstack-press-room-article'));
const ContentstackPressRoomListPageTastic = dynamic(() => import('./contentstack/contentstack-press-room-list-page'));
const ContentstackProductOverviewTastic = dynamic(() => import('./contentstack/contentstack-product-overview'));
const ContentstackServiceAdvisoryArticleTastic = dynamic(
  () => import('./contentstack/contentstack-service-advisory-article'),
);
const ContentstackServiceAdvisoryListPageTastic = dynamic(
  () => import('./contentstack/contentstack-service-advisory-list-page'),
);
const ContentstackStaticContentTastic = dynamic(() => import('./contentstack/contentstack-static-content'));
const ContentstackTradeInFormTastic = dynamic(() => import('./contentstack/contentstack-trade-in-form'));
const ContentstackTradeInLandingTastic = dynamic(() => import('./contentstack/contentstack-trade-in-landing'));
const Missing = dynamic(() => import('./missing'));
const NotFoundTastic = dynamic(() => import('./not-found'));
const AccountDetails = dynamic(() => import('./account/details'));
const AccountLogin = dynamic(() => import('./account/login'));
const AccountOrdersHistory = dynamic(() => import('./account/orders'));
const AccountRegister = dynamic(() => import('./account/register'));
const ResetPassword = dynamic(() => import('./account/reset-password'));
const Checkout = dynamic(() => import('./checkout'));
const ThankYou = dynamic(() => import('./thank-you'));
const ProductDetails = dynamic(() => import('./products/details'));
const ProductList = dynamic(() => import('./products/product-list'));
const SimilarProducts = dynamic(() => import('./products/similar-products'));
const Cart = dynamic(() => import('./cart'));

export const tastics = {
  'commercetools/ui/checkout': Checkout,
  'commercetools/ui/thank-you': ThankYou,
  'commercetools/ui/content/tile': Tile,
  'commercetools/ui/content/hero': HeroTastic,
  'commercetools/ui/cart': Cart,
  'commercetools/ui/products/details': ProductDetails,
  'commercetools/ui/products/product-list': ProductList,
  'commercetools/ui/products/similar-products': SimilarProducts,
  'commercetools/ui/account/details': AccountDetails,
  'commercetools/ui/account/login': AccountLogin,
  'commercetools/ui/account/register': AccountRegister,
  'commercetools/ui/account/orders': AccountOrdersHistory,
  'commercetools/ui/account/reset-password': ResetPassword,
  'commercetools/ui/category-slider': CategorySliderTastic,
  'commercetools/ui/content/announcement-bar': AnnouncementBarTastic,
  'commercetools/ui/content/content-slider': ContentSliderTastic,
  'commercetools/ui/content/tiles-group': TilesGroupTastic,
  'commercetools/ui/not-found': NotFoundTastic,

  // contentstack
  'contentstack/contentstack-category-landing': ContentstackCategoryLandingTastic,
  'contentstack/contentstack-content': ContentstackContentTastic,
  'contentstack/contentstack-content-list': ContentstackContentListTastic,
  'contentstack/contentstack-lne-article-page': ContentstackLNEArticlePageTastic,
  'contentstack/contentstack-lne-category-page': ContentstackLNECategoryPageTastic,
  'contentstack/contentstack-lne-collection-page': ContentstackLNECollectionPageTastic,
  'contentstack/contentstack-lne-glossary': ContentstackLNEGlossaryTastic,
  'contentstack/contentstack-lne-homepage': ContentstackLNEHomepageTastic,
  'contentstack/contentstack-lne-search-results': ContentstackLNESearchResultsTastic,
  'contentstack/contentstack-nikon-ambassador': ContentstackNikonAmbassadorsTastic,
  'contentstack/contentstack-nikon-mentors': ContentstackNikonMentorsTastic,
  'contentstack/contentstack-page-single': ContentstackPageSingleTastic,
  'contentstack/contentstack-product-overview': ContentstackProductOverviewTastic,
  'contentstack/contentstack-press-room-article': ContentstackPressRoomArticleTastic,
  'contentstack/contentstack-press-room-list-page': ContentstackPressRoomListPageTastic,
  'contentstack/contentstack-service-advisory-article': ContentstackServiceAdvisoryArticleTastic,
  'contentstack/contentstack-service-advisory-list-page': ContentstackServiceAdvisoryListPageTastic,
  'contentstack/contentstack-static-content': ContentstackStaticContentTastic,
  'contentstack/contentstack-trade-in-landing': ContentstackTradeInLandingTastic,
  'contentstack/contentstack-trade-in-form': ContentstackTradeInFormTastic,

  // nikon
  'nikon/ui/account/order-status': OrderStatusTastic,
  'nikon/ui/account/shipping-label': ShippingLabelTastic,

  default: Missing,
};
