type DomainUrls = {
  readonly [key: string]: string | undefined;
};

const DOMAIN_URLS: DomainUrls = {
  'en-US': process.env.NEXT_PUBLIC_CANONICAL_URL_EN_US,
  'en-CA': process.env.NEXT_PUBLIC_CANONICAL_URL_EN_CA,
  'fr-CA': process.env.NEXT_PUBLIC_CANONICAL_URL_FR_CA,
  'es-MX': process.env.NEXT_PUBLIC_CANONICAL_URL_ES_MX,
  'es-419': process.env.NEXT_PUBLIC_CANONICAL_URL_ES_419,
  'pt-BR': process.env.NEXT_PUBLIC_CANONICAL_URL_PT_BR,
} as const;

export const getDomainName = (locale: string) => DOMAIN_URLS[locale];
