import React from 'react';
import Script from 'next/script';
import { TURN_TO_SITE_ID } from 'helpers/constants/turnTo';

const TurnToSnippet = ({ sku }: any) => {
  return (
    <>
      <Script src={`https://widgets.turnto.com/v5/widgets/${TURN_TO_SITE_ID}/js/turnto.js`} />
      <Script id="turn-to">
        {`
          var turnToConfig = {
            locale: "en_US",
            pageId: "pdp-page",
            sku: '${sku}',
            reviewsList: {
              // onFinish: function(){}
            }
          };
          window.TurnToCmd=window.TurnToCmd||function(){(TurnToCmd.q=TurnToCmd.q||[]).push(arguments)};
        `}
      </Script>
    </>
  );
};

export default TurnToSnippet;
